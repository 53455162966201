.modal-card .card-body {
    max-height: 80vh;
}

.card-app-logo {
    display: block;
    height: 40px;
    max-width: 90%;
}

.card-app-logo-lg {
    height: 68px;

    > .product-img {
        max-height: 70px;
        max-width: 90%;
        width: unset;
    }
}
