@import "card";
@import "input";
@import "layout";
@import "placeholder";
@import "wizard";

//
// user.scss
// Use this to write your custom SCSS
//

a {
    cursor: pointer;
}

.navbar-collapse::before {
    border-top-width: 0;
}

.nav-link {
    white-space: nowrap;
}

.main-content {
    min-height: calc(100vh - 71px - 253px);
}

.fe-align-bottom {
    vertical-align: bottom;
}

.borderless {
    border: 0;
}

.start-hero {
    background-image: url(../../public/img/covers/start-hero.webp);
    background-repeat: no-repeat, no-repeat;
    background-position: bottom center;
    background-size: cover, cover;
}

.start-hero.no-webp {
    background-image: url(../../public/img/covers/start-hero.png);
}

.hyphen {
    hyphens: auto;
}
