.navbar-top {
  margin-left: 250px;
}

.navbar-pt {
  padding-top: 65px;
}

.navbar-brand {
  > div {
    vertical-align: middle;
  }
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding: 0.625rem 0.75rem;
}

.close-btn {
  position: fixed;
  top: 100px;
  right: 34px;
}

.navbar-brand-img {
  width: 70%;
}

.product-img {
  max-width: 100%;
  max-height: 45px;
}

.bg-footer {
  background-color: $gray-200;
}

.list-footer {
  a {
    &:hover {
      color: $dark;
    }

    color: $secondary;
  }
}
