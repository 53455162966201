/**
 * Dashkit
 *
 * Custom variables followed by Dashkit variables followed by Bootstrap variables
 * to ensure cascade of styles.
 */

// Feather icons
@import "feather.css";

// Bootstrap functions
@import "../../node_modules/bootstrap/scss/functions";

// Custom variables
@import "user-variables";

// Dashkit variables
@import "dashkit/variables";

// Bootstrap core
@import "../../node_modules/bootstrap/scss/bootstrap";

// Dashkit core
@import "dashkit/dashkit";

// Custom core
@import "user";

// React-slick
@import "../../node_modules/slick-carousel/slick/slick";
@import "../../node_modules/slick-carousel/slick/slick-theme";
// Slick slider adjustments
@import "slick-slider";
