$transition-time: 0.2s;

.step-circles {
    border-bottom: none;

    .nav-link {
        border: 0;
        position: relative;
        padding: 0;
        width: $spacer * 2;
        height: $spacer * 2;
        background: rgba($primary, 0.1);
        border-radius: 50%;
        color: $primary !important;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1 !important;
        transition: background-color $transition-time ease;

        &:hover {
            background: rgba($primary, 0.15);
        }
    }

    .nav-item {
        margin: 0 $spacer;
        opacity: 0.5;

        &.active,
        &.done {
            .nav-link {
                background: $primary;
                color: $white !important;
            }
        }

        &.active,
        &.done {
            opacity: 1;
        }

        &:not(:last-child) {
            .nav-link {
                &:after {
                    content: "";
                    width: $spacer * 2;
                    height: 2px;
                    position: absolute;
                    left: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                    background: rgba($primary, 0.1);
                    pointer-events: none;
                    transition: background-color $transition-time ease;
                }
            }
        }

        &.done {
            &:not(:last-child) {
                .nav-link {
                    &:after {
                        background: $primary;
                    }
                }
            }
        }
    }
}

.wizard {
    > ul:not(.step-circles) {
        .btn {
            border-radius: $border-radius $border-radius 0 0;
        }

        > li.active {
            .btn {
                opacity: 1 !important;
                background: $primary;
                color: $white;
            }
        }
    }
}

.animated {
    animation-fill-mode: backwards;
    transform-style: preserve-3d;
    transition-timing-function: ease-in;
}

/* Fading animations  */
@-webkit-keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-33%, 0, 0);
        transform: translate3d(-33%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
@keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-33%, 0, 0);
        transform: translate3d(-33%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
.animate__fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    animation-duration: 0.6s;
    animation-delay: 0.2s;
}

@-webkit-keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(33%, 0, 0);
        transform: translate3d(33%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
@keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(33%, 0, 0);
        transform: translate3d(33%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
.animate__fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
    animation-duration: 0.6s;
    animation-delay: 0.2s;
}

@-webkit-keyframes fadeOutLeft {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-33%, 0, 0);
        transform: translate3d(-33%, 0, 0);
    }
}
@keyframes fadeOutLeft {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-33%, 0, 0);
        transform: translate3d(-33%, 0, 0);
    }
}
.animate__fadeOutLeft {
    -webkit-animation-name: fadeOutLeft;
    animation-name: fadeOutLeft;
    animation-duration: 0.3s;
}

@-webkit-keyframes fadeOutRight {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(33%, 0, 0);
        transform: translate3d(33%, 0, 0);
    }
}
@keyframes fadeOutRight {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(33%, 0, 0);
        transform: translate3d(33%, 0, 0);
    }
}
.animate__fadeOutRight {
    -webkit-animation-name: fadeOutRight;
    animation-name: fadeOutRight;
    animation-duration: 0.3s;
}

.honeypot {
    position: absolute;
    top: -10000px;
    left: -10000px;
}
