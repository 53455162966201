.slick-track {
    display: flex;
    align-items: stretch;
}

.slick-slide {
    display: flex;
    align-self: stretch;
    height: auto;
    > div {
        display: flex;
        align-self: stretch;
        width: 100%;
    }

    img {
        height: 60vh;
        margin-right: 20px;
    }
}

.slick-dots {
    bottom: -35px;
}

.slick-dots li.slick-active button::before,
.slick-dots li button::before {
    color: $primary;
}

.slick-dots li button::before {
    font-size: 12px;
}

.slick-arrow {
    display: none !important;
}
