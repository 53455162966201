//
// user-variables.scss
// Use this to overwrite Bootstrap and Dashkit variables
//

// Example of a variable override to change Dashkit's background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

$blue: #225eaa;
$green: #00cea9;

// Change bg-dark to a dark blue
$black: #0f2e52;
$black-dark: #0f2e52;

$headings-line-height: 1.25;
